import React, { useState } from "react";
import styled from "styled-components";
import { appTheme } from "../../../project/brand/project";
import { Select } from "../../elements";
import { GetIcon } from "../../../../icons";

// Styled components
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  position: sticky;
  /* bottom: 0; */
  background-color: ${appTheme.bg.white};
  padding: 10px 0;
`;

const PaginationInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
`;

const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  button {
    border: none;
    border-radius: 6px;
    background-color: ${appTheme.bg.white};
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 32px;
    height: 32px;
    padding: 6px;
    gap: 6px;
    border: 1px 0px 0px 0px;
    opacity: 0.9;
    box-shadow: 0px 1px 2px 0px #e4e5e73d;
    border: 1px solid ${appTheme.stroke.soft};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: center;
    color: ${appTheme.text.main};

    &:disabled {
      background-color: ${appTheme.bg.weak};
      cursor: not-allowed;
    }
    &.plain {
      border: 0;
      background-color: transparent;
      box-shadow: none;
    }
    &:hover:not(:disabled) {
      background-color: ${appTheme.bg.weak};
    }
    svg{
      color: ${appTheme.icon.sub};

    }
  }
`;

const RowsPerPage = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }

  select {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
    background-color: white;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

// Pagination Component
const Pagination = ({ totalRows, onClick, perPage }) => {
  const rowsPerPageOptions = [  
    ...(perPage !== 10 ? [{ id: perPage, value: `Default (${perPage})` }] : []),  
    { id: 10, value: "10 / page" },  
    { id: 25, value: "25 / page" },  
    { id: 50, value: "50 / page" },  
    { id: 100, value: "100 / page" },  
    { id: 250, value: "250 / page" }  
];
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(perPage);
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  // useEffect(() => {
  //   onClick((currentPage - 1) * rowsPerPage);
  // }, [currentPage, rowsPerPage, onClick]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onClick((page - 1) * rowsPerPage, rowsPerPage);
  };

  const handleRowsPerPageChange = (val) => {
    console.log("Current Index:", val);
    setRowsPerPage(val.id);
    setCurrentPage(1); // Reset to the first page when changing rows per page
    onClick((currentPage - 1) * val.id, val.id);
  };

  const [currentPageStartIndex, setCurrentPageStartIndex] = useState(1); // Start from page 1

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = Math.min(totalPages - currentPageStartIndex + 1, 4); // Maximum of 4 pages to show

    // Show the current pages based on the currentPageStartIndex
    for (let i = currentPageStartIndex; i < currentPageStartIndex + maxVisiblePages; i++) {
      if (i <= totalPages) {
        pageNumbers.push(
          <button key={i} onClick={() => handlePageChange(i)} disabled={i === currentPage}>
            {i}
          </button>
        );
      }
    }

    // Show ellipsis if there are more pages after the current set
    if (currentPageStartIndex + maxVisiblePages <= totalPages) {
      pageNumbers.push(
        <button
          key="more"
          onClick={() => {
            setCurrentPageStartIndex(currentPageStartIndex + 4); // Load the next set of pages
          }}
        >
          ...
        </button>
      );
    }

    // Check if there are pages before the current set
    if (currentPageStartIndex > 1) {
      pageNumbers.unshift(
        <button
          key="prev"
          onClick={() => {
            setCurrentPageStartIndex(Math.max(currentPageStartIndex - 4, 1)); // Go back to the previous set of pages
          }}
        >
          ...
        </button>
      );
    }

    return pageNumbers;
  };
  return (
    <PaginationContainer>
      <PaginationInfo>
        <span>Page </span>
        {currentPage} of {totalPages} • {totalRows} Recods
      </PaginationInfo>
      {rowsPerPage < totalRows && (
        <PaginationControls>
          {totalPages > 2 && (
            <button className="plain" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
              <GetIcon icon={"ArrowDoubleLeft"} />
            </button>
          )}
          {totalPages > 1 && (
            <button className="plain" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              <GetIcon icon={"ArrowLeft"} />
            </button>
          )}
          {renderPageNumbers()}
          {totalPages > 1 && (
            <button className="plain" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              <GetIcon icon={"arrowRight"} />
            </button>
          )}
          {totalPages > 2 && (
            <button className="plain" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
              <GetIcon icon={"ArrowRightDoubleIcon"} />
            </button>
          )}
        </PaginationControls>
      )}
      <RowsPerPage>{rowsPerPage && <Select align="auto" value={rowsPerPage} label="" defaultValue={rowsPerPage} onSelect={(value) => handleRowsPerPageChange(value)} selectApi={rowsPerPageOptions} />}</RowsPerPage>
    </PaginationContainer>
  );
};

export default Pagination;
