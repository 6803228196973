import { useSelector } from "react-redux";
import { ElementContainer, MultiTabs } from "../elements";
import Layout from "../layout";
import { ProfileBanner } from "./styles";
import { generateThumbnail } from "../functions/string";
import { useEffect, useState } from "react";
import ChangePassword from "./changepassword";
import PopupView from "../popupview";
import { useNavigate } from "react-router-dom";
import customSettings from "../../project/settings";
import { projectSettings } from "../../project/brand/project";

const ProfileSettings = (props) => {
  const themeColors = useSelector((state) => state.themeColors);
  const user = useSelector((state) => state.login);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Profile - ${projectSettings.title}`;
    setUserData(user.data.user);
  }, [user]);
  return (
    userData && (
      <PopupView
        // Popup data is a JSX element which is binding to the Popup Data Area like HOC
        popupData={
          <ElementContainer className="custom column">
            <ProfileBanner theme={themeColors}>
              <div className="data">
                {generateThumbnail(userData?.fullName ?? userData?.username ?? "", null, userData.photo ?? "")}
                <div className="address">
                  <h2>{userData.fullName ?? "No Found"}</h2>
                  <h3>{userData.email ?? "No Found"}</h3>
                  <h3>{userData.userType.role ?? "No Found"}</h3>
                </div>
              </div>
            </ProfileBanner>
            <MultiTabs
              tabs={[
                ...customSettings(userData),
                {
                  name: `a-unique-name-for-tab-2`,
                  title: "Security Settings",
                  content: <ChangePassword />,
                  icon: "password",
                },
              ]}
            ></MultiTabs>
          </ElementContainer>
        }
        themeColors={themeColors}
        closeModal={() => navigate(-1)}
        itemTitle={{ name: "title", type: "text", collection: "" }}
        // openData={openItemData} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
        openData={{
          data: {
            _id: "print_preparation",
            title: "Profile Settings",
          },
        }}
        customClass={"full-page"}
      ></PopupView>
    )
  );
};
export default Layout(ProfileSettings);
