import React, { lazy, Suspense } from "react";
import Loader from "../../core/loader";

// Lazy load all components
const Landing = lazy(() => import("../pages/landing/landing"));
const Demo = lazy(() => import("../pages/landing/demo"));
const Menu = lazy(() => import("../../project/pages/menu"));
const Franchise = lazy(() => import("../../project/pages/franchise"));
const Page404 = lazy(() => import("../../project/pages/page404"));
const UserType = lazy(() => import("../../project/pages/user/userType"));
const Dashboard = lazy(() => import("../../project/pages/dashboard"));
const Admin = lazy(() => import("../../project/pages/franchise/admin"));
const Collection = lazy(() => import("../../project/pages/collection"));
const Faq = lazy(() => import("../../project/pages/faq"));
const Gallery = lazy(() => import("../../project/pages/gallery"));
const News = lazy(() => import("../../project/pages/news"));
const Speakers = lazy(() => import("../../project/pages/speakers"));
const Registration = lazy(() => import("../../project/pages/registration"));
const Testimonial = lazy(() => import("../../project/pages/testimonial"));
const Event = lazy(() => import("../../project/pages/event"));
const EventUser = lazy(() => import("../../project/pages/eventUser"));
const CountDate = lazy(() => import("../pages/count"));
const Deconquista = lazy(() => import("../../project/pages/deconquista"));
const LeadersNote = lazy(() => import("../../project/pages/leadersNote"));
const PaidReg = lazy(() => import("../../project/pages/paidReg"));
const Attendance = lazy(() => import("../../project/pages/attendance"));
const PageSection = lazy(() => import("../../project/pages/pageSection"));
const SectionTheme = lazy(() => import("../../project/pages/sectionTheme"));
const Fpackage = lazy(() => import("../../project/pages/franchisePackage"));
const Packages = lazy(() => import("../../project/pages/package"));
const Settings = lazy(() => import("../pages/settings"));
const WhitelistedDomains = lazy(() => import("../pages/whitelistedDomains"));
const Ticket = lazy(() => import("../pages/ticket"));
const TicketFormData = lazy(() => import("../pages/ticketFormData"));
const TicketRegistration = lazy(() => import("../pages/ticketFormData/ticketRegistration"));
const LandingPageConfig = lazy(() => import("../pages/landingPageConfig"));
const Elements = lazy(() => import("../pages/settings/elements"));
const CertificationData = lazy(() => import("../pages/certificationData"));
const Authentication = lazy(() => import("../pages/authentication"));
const Country = lazy(() => import("../pages/country"));
const Currency = lazy(() => import("../pages/currency"));
const TicketAdmin = lazy(() => import("../pages/event/ticketAdmin"));
const MenuItem = lazy(() => import("../pages/menuItem"));
const Projects = lazy(() => import("../pages/projects"));
const Model = lazy(() => import("../pages/model/index"));
const LayoutComponent = lazy(() => import("../pages/layout/index"));
const SortFilter = lazy(() => import("../pages/sortFilter/index"));
const Exhibitor = lazy(() => import("../pages/exhibitor/index"));
const ExhibitorCategory = lazy(() => import("../pages/exhibitorCategory/index"));
const RegistrationsLead = lazy(() => import("../pages/registrationsLead/index"));
const Team = lazy(() => import("../pages/team/index"));
const FormBuilder = lazy(() => import("../pages/formBuilder/index"));
const BadgeCertificate = lazy(() => import("../pages/badge/index"));
const SessionType = lazy(() => import("../pages/sessionType/index"));
const EventCategory = lazy(() => import("../pages/eventCategory/index"));
const PartnersSpotlight = lazy(() => import("../pages/partnersSpotlight/index"));
const AddonProductCategory = lazy(() => import("../pages/addonProductCategory/index"));
const PaymentMethod = lazy(() => import("../pages/paymentMethod/index"));
const Template = lazy(() => import("../pages/template/index"));
const ParticipantCategory = lazy(() => import("../pages/participantCategory/index"));
const SpeakerCategory = lazy(() => import("../pages/speakerCategory/index"));
const GraphType = lazy(() => import("../pages/graphType/index"));
const AddOn = lazy(() => import("../pages/addOn/index"));
const EventModule = lazy(() => import("../pages/eventModule/index"));
const AddOnPrice = lazy(() => import("../pages/addOnPrice/index"));

const RenderPage = (page, key, user, privileges) => {
  const renderComponent = (Component) => (
    <Suspense fallback={<Loader>Loading...</Loader>}>
      <Component key={key} {...privileges} />
    </Suspense>
  );
  switch (page) {
    case "login":
      return renderComponent(Landing);
    case "demo-landing":
      return renderComponent(Demo);
    case "projects":
      return renderComponent(Projects);
    case "menu":
      return renderComponent(Menu);
    case "franchise":
      return renderComponent(Franchise);
    case "user-role":
      return renderComponent(UserType);
    case "admin":
      return renderComponent(Admin);
    case "faq":
      return renderComponent(Faq);
    case "dashboard":
      return renderComponent(Dashboard);
    case "collection":
      return renderComponent(Collection);
    case "gallery":
      return renderComponent(Gallery);
    case "news":
      return renderComponent(News);
    case "speakers":
      return renderComponent(Speakers);
    case "registration":
      return renderComponent(Registration);
    case "testimonial":
      return renderComponent(Testimonial);
    case "event":
      return renderComponent(Event);
    case "event-user":
      return renderComponent(EventUser);
    case "count":
      return renderComponent(CountDate);
    case "deconquista":
      return renderComponent(Deconquista);
    case "leadersNote":
      return renderComponent(LeadersNote);
    case "paid-reg":
      return renderComponent(PaidReg);
    case "attendance":
      return renderComponent(Attendance);
    case "page-section":
      return renderComponent(PageSection);
    case "section-theme":
      return renderComponent(SectionTheme);
    case "franchise-package":
      return renderComponent(Fpackage);
    case "package":
      return renderComponent(Packages);
    case "settings":
      return renderComponent(Settings);
    case "whitelistedDomains":
      return renderComponent(WhitelistedDomains);
    case "ticket":
      return renderComponent(Ticket);
    case "ticket-form-data":
      return renderComponent(TicketFormData);
    case "ticket-registration":
      return renderComponent(TicketRegistration);
    case "landingPageConfig":
      return renderComponent(LandingPageConfig);
    case "elements":
      return renderComponent(Elements);
    case "certification-data":
      return renderComponent(CertificationData);
    case "authentication":
      return renderComponent(Authentication);
    case "event-admin":
      return renderComponent(Event);
    case "demo":
      return renderComponent(Demo);
    case "country":
      return renderComponent(Country);
    case "currency":
      return renderComponent(Currency);
    case "ticket-admin":
      return renderComponent(TicketAdmin);
    case "menu-item":
      return renderComponent(MenuItem);
    case "model":
      return renderComponent(Model);
    case "layout":
      return renderComponent(LayoutComponent);
    case "sortFilter":
      return renderComponent(SortFilter);
    case "exhibitor":
      return renderComponent(Exhibitor);
    case "exhibitor-category":
      return renderComponent(ExhibitorCategory);
    case "registrations-lead":
      return renderComponent(RegistrationsLead);
    case "team":
      return renderComponent(Team);
    case "formBuilder":
      return renderComponent(FormBuilder);
    case "badge-certificate":
      return renderComponent(BadgeCertificate);
    case "session-type":
      return renderComponent(SessionType);
    case "event-category":
      return renderComponent(EventCategory);
    case "partners-spotlight":
      return renderComponent(PartnersSpotlight);
    case "addon-product-category":
      return renderComponent(AddonProductCategory);
    case "payment-method":
      return renderComponent(PaymentMethod);
    case "template":
      return renderComponent(Template);
    case "participant-category":
      return renderComponent(ParticipantCategory);
    case "speaker-category":
      return renderComponent(SpeakerCategory);
    case "graph-type":
      return renderComponent(GraphType);
    case "add-on":
      return renderComponent(AddOn);
    case "event-module":
      return renderComponent(EventModule);
    case "add-on-price":
      return renderComponent(AddOnPrice);
    default:
      return renderComponent(Page404);
  }
};

export default RenderPage;
