import React, { useMemo } from "react";
import { GetIcon } from "../../../icons";
import { RowContainer } from "../../styles/containers/styles";
import { PageHeader } from "../input/heading";
import { getValue } from "../list/functions";
import { Header, Page } from "../list/manage/styles";
import { CloseButton } from "../list/popup/styles";
import { Overlay } from "../message/styles";

const PopupView = React.memo(
  ({ themeColors, closeModal, itemTitle, description = "", popupData, customClass, openData }) => {
    const titleValue = useMemo(() => {
      return (itemTitle.collection?.length > 0 ? openData?.data?.[itemTitle.collection]?.[itemTitle.name] : openData?.data?.[itemTitle.name]) ?? "Please update the itemTitle.";
    }, [itemTitle, openData]);

    return (
      <Overlay key={openData.data._id} className={`${customClass ?? "medium"}`}>
        <Page className={`${customClass ?? "medium"} popup-child`}>
          <Header className="custom">
            <PageHeader title={getValue(itemTitle, titleValue)} line={false} description={description}></PageHeader>

            <CloseButton theme={themeColors} onClick={closeModal}>
              <GetIcon icon={"Close"} />
            </CloseButton>
          </Header>
          <RowContainer theme={themeColors} className={`${customClass ?? "medium"} popup-data`}>
            {popupData}
          </RowContainer>
        </Page>
      </Overlay>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.themeColors === nextProps.themeColors && prevProps.itemTitle === nextProps.itemTitle && prevProps.description === nextProps.description && prevProps.customClass === nextProps.customClass && prevProps.openData.data._id === nextProps.openData.data._id;
  }
);

export default PopupView;
